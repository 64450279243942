.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space);
    color: var(--primary);

    background: var(--chipsBgColor);

    border: 1px solid var(--primary);
    border-radius: var(--chipsBorderRadius);
    font-size: var(--font-size--md);
    text-align: center;
}
.participantsCountContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}
.mobile {
    font-size: var(--font-size--sm);
}
.icon {
    width: calc(3 * var(--space));
    height: calc(3 * var(--space));
}