.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.imageContainer {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
}

.messageContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--primary);
    background: var(--chipsBgColor);

    padding: calc(2* var(--space));
    margin: calc(2* var(--space)) 0;

    border: 1px solid var(--primary);
    border-radius: var(--chipsBorderRadius);

    font-family: Inter, sans-serif;
    font-size: var(--font-size--lg);

    width: 100%;
    min-height: calc(10 * var(--space));
}

.spinner {
    position: absolute;
    top: 75%;
}