.container {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
}
.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}