.StreamInfoSectionV2 {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    max-width: 450px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: left;
}

.eventTimeTitle {
    font-weight: 700;
    font-size: 16px;
    color: #828BE1;
    margin-bottom: 20px;
}

.eventLive {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.eventLiveTitle {
    font-weight: 600;
    font-size: 16px;
    color: #F56969;
}

.liveIndicator {
    width: 19px;
    height: 19px;
    margin-right: 5px;
}

.eventTitle {
    font-weight: 700;
    font-size: 18px;
    color: #0F111C;
    margin-bottom: 8px;
}


.hostBottom {
    display: flex;
    align-items: center;
}

.text {
    font-weight: 700;
    font-size: 20px;
    color: #494E54;
}

.avatarImage {
    object-fit: cover;
    margin: 8px 8px 8px 0;
}

.hostName {
    font-weight: 600;
    font-size: 16px;
    color: #0F111C;
    max-width: 270px;
}
