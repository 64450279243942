.container {
  position: relative;
}
.muteContainer {
  position: absolute;
  top: calc(2 * var(--space));
  left: calc(5 * var(--space));
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}