.streamControlsContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.largeVideoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(3 * var(--space));
}

.invisible {
    visibility: hidden;
}